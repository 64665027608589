var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-card',{staticClass:"no-border-card medium-collapsable-card",class:{
			'whole-row': _vm.wholeRow,
			'stretch-height': _vm.stretchHeight,
			'most-left': _vm.mostLeft,
			showContent: _vm.isContentShown,
		},attrs:{"footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('b-col',{staticClass:"d-flex justify-content-between align-items-center flex-wrap card-header-title",attrs:{"cols":"12"},on:{"click":_vm.showContent}},[_c('H3',{attrs:{"title":_vm.title}}),_c('b-icon',{class:{
						rotate: _vm.isContentShown,
					},attrs:{"icon":"chevron-right"}})],1)],1),[_vm._t("content")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }